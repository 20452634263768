import React from "react";

import Layout from "../Components/Layout";
import Invitation from "../Components/Invitation";

const InvitationPage = () => {
  return (
    <Layout isPrivate showHeader>
      <Invitation />
    </Layout>
  );
};

export default InvitationPage;
